import React from "react"
import * as styles from "./photo-gallery.module.scss"
import GalleryTile from "./GalleryTile"
const GalleryList = ({ galleries, enabled }) => {
  if (enabled === "kontent_item_pest") {
    return (
      <section className={`container ${styles.pestGrid}`}>
        {galleries.value.map(({ elements, id }) => {
          return <GalleryTile key={id} gallery={elements} />
        })}
      </section>
    )
  } else {
    return null
  }
}
export default GalleryList
