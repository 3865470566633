import { Link } from "gatsby"
import React, { useContext } from "react"
import Img from "gatsby-image"
import * as styles from "./photo-gallery.module.scss"
import { AppContext } from "../context/context"

const GalleryTile = ({ gallery }) => {
  const { slug, name, image } = gallery
  const { photoGalleryActive, photoGallerySlug } = useContext(AppContext)
  const gallerySlug = photoGalleryActive
    ? `/${photoGallerySlug}/${slug.value}`
    : `/${slug.value}`
  return (
    <Link to={gallerySlug} className={styles.pest}>
      <Img
        fluid={image.value[0].fluid}
        alt={image.value[0].description}
        className={styles.pestImage}
      />
      <h3>{name.value}</h3>
    </Link>
  )
}

export default GalleryTile
